
import { getCookie} from "../util/index.js"
import * as echarts from 'echarts';
export default {
    data(){
        return {
            range:[],
            aftersale:"",
            totalorderamount:'',
            totalordercount:"",
            isloading:false,
            storeid:"",
            activename:'1',
            labels:[
				{
					name:'日',
					value:'1'
				},
				{
					name:"月",
					value:'2'
				},
				{
					name:"年",
					value:"3"
				}
			]
        }
    },
    methods:{
        handleClick(){
			console.log(this.activename);
			this.axios.get(this.actions.statdialog,{
				headers:{
					"content-type":"application"
				},
				params:{
					id:this.storeid,
					type:this.activename,
					range:JSON.stringify(this.range)
				}
			}).then((response)=>{
				console.log(response.data);
				this.totalorderamount = response.data.TotalOrderAmount;
				this.totalordercount = response.data.TotalOrderCount;
                this.aftersale = response.data.AfterSaleCount;
				let name = "statchart" + this.activename;
				this.MyCharts = echarts.init(document.getElementById(name));
				var option = {
					legend:{
						data:['交易额']
					},
					xAxis: {
						type:'category',
						data:response.data.xAxis
					},
					yAxis: {
						type: 'value'
					},
					datazoom:[
						{
							type:'inside',
							start: 0,
							end:20
						},
						{
							start: 0,
							end:20
						}
					],
					series: [
						{
							name:'交易额',
							data:response.data.yAxis,
							type:'bar'
							
						}
					]
				}
				this.MyCharts.setOption(option,true);
				
			});
		},
        Statistic(id){
			console.log(id);
			this.isloading = true;
			this.axios.get(this.actions.statdialog,{
				headers:{
					"content-type":"application"
				},
				params:{
					id:id,
					type:this.activename,
					range:JSON.stringify(this.range)
				}
			}).then((response)=>{
				console.log(response.data);
				this.totalorderamount = response.data.TotalOrderAmount;
				this.totalordercount = response.data.TotalOrderCount;
                this.aftersale = response.data.AfterSaleCount;
				let name = "statchart" + this.activename;
				this.MyCharts = echarts.init(document.getElementById(name));
				var option = {
					legend:{
						data:['交易额']
					},
					
					xAxis: {
						type:'category',
						data:response.data.xAxis
					},
					yAxis: {
						type: 'value'
					},
					datazoom:[
						{
							type:'inside',
							start: 0,
							end:20
						},
						{
							start: 0,
							end:20
						}
					],
					series: [
						{
							name:'交易额',
							data:response.data.yAxis,
							type:'bar'
							
						}
					]
				}
				this.MyCharts.setOption(option,true);
				this.isloading = false;
			});
			
		},
    },
    created:function(){
        let storeid = getCookie("storeid");
        this.storeid = storeid;
        this.Statistic(this.storeid);
    }
}
